.LeftTitle {
    font-family: 'Inter', sans-serif;
    font-size: 32px;
    font-weight: normal;
    color: black;
    text-align: left;
}

@media (max-width: 768px) {
    .LeftTitle {
        font-size: 24px;
        text-align: center;
    }
}

.RightTitle {
    font-family: 'Leckerli One', cursive;
    font-size: 60px;
    color: black;
    text-align: right;
}

@media (max-width: 768px) {
    .RightTitle {
        font-size: 50px;
        /* Adjusted font size for readability */
        text-align: center;
        margin-top: -20px;
        /* Negative margin to pull the title up */
    }
}