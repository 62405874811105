.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #e9e6e6;
    height: 100px;
}

.leftText {
    font-family: 'Inter', sans-serif;
    font-size: 32px;
    font-weight: normal;
    text-align: left;
}

.rightText {
    font-family: 'Leckerli One', cursive;
    font-size: 70px;
    text-align: right;
}

/* Responsive adjustments for screens with a maximum width of 768px */
@media (max-width: 768px) {
    .header {
        flex-direction: column;
        height: 160px;
        padding: 10px;
    }

    .leftText,
    .rightText {
        font-size: 24px;
        /* Reduce font size for smaller screens */
        text-align: center;
        /* Center text for better mobile readability */
    }

    .rightText {
        font-size: 36px;
        /* Slightly larger font size for the right text */
    }
}