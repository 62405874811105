.column {
    flex-grow: 1;
    flex-basis: 0;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    background-color: inherit;
    perspective: 1000px;
    /* Enables 3D space for the flipping effect */
}

.flipCardInner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transform-style: preserve-3d;
    /* Keeps child elements in 3D space */
    transition: transform 0.8s;
    /* Smooth transformation for flip effect */
}

.flipCardFront,
.flipCardBack {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    /* Hide the back face when not facing the screen */
}

.flipCardFront {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.flipCardBack {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    /* Set the background color of the back to white */
    color: #333;
    /* Text color for back content */
    transform: rotateY(180deg);
    /* Initial position rotated to hide back */
}

/* Ensures flip shows the correct side based on state */
.column.is-flipped .flipCardInner {
    transform: rotateY(180deg);
    /* Rotate the whole inner card to show the back */
}

.flipButton {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 5;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: transparent;
    /* Start with a transparent background */
    color: #ffffff;
    /* Light purple border that looks good with the gradient */
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.8em;
    /* Larger font size for better visibility */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    /* Subtle shadow for depth */
    transition: all 0.1s ease;
    /* Smooth transition for all properties */
}

.flipButton:hover,
.flipButton:focus {
    /* Removed border-color: transparent; to keep the original border */
    transform: scale(1.1);
    /* Scale up the button slightly on hover */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.4);
    /* Increased shadow on hover for more depth */
    color: #ffffff;
    /* Ensure the font color is white for better visibility */
}

.flipButton:before {
    content: '↻';
    /* Unicode symbol for a simple, clean icon */
    display: block;
}
.imageContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

.chainImage {
    border-radius: 50%;
    margin-top: 10px;
    width: 50px;
    height: 50px;
}

.profileImage {
    border-radius: 50%;
    margin-top: 20px;
    width: 225px;
    height: 225px;
    border: 1px solid #000;
}

.buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 146px;
    padding: 10px 30px;
    cursor: pointer;
    background: #9d2b13;
    border: none;
    border-radius: 20px;
    color: #ffffff;
    font-family: 'Maven Pro', sans-serif;
    font-size: 20px;
    font-weight: 600;
    margin: 6px 0;
    text-align: center;
    white-space: nowrap;
    letter-spacing: 1px;
}

.btn__fill-effect {
    background: linear-gradient(to top, #22ffcb, #65642E);
}

.socialButton {
    background: none;
    border: none;
    cursor: pointer;
}

.socialIcons {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

/* Column.css */
.crack-effect {
    background-image: url('../assets/cracks.png');
    background-size: cover;
    background-repeat: no-repeat;
}

@media (max-width: 768px) {
    .columnsContainer {
        flex-direction: column;
    }

    .column {
        width: 100%;
        margin-bottom: 0;
    }

    .flipButton {
        width: 50px;
        height: 50px;
        bottom: 10px;
        right: 10px;
        font-size: 1.5em;
    }
}

